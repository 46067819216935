/**
 * .interviews-intro-wrapper.
 */

.interviews-intro-wrapper {

    padding-top: 3rem;
    padding-bottom: 1rem;

    background-color: $brand-three;

    /**
     * .interviews-intro-container.
     */

    .interviews-intro-container {

        

    }

}