/**
 * .home-shopitems-wrapper.
 */

.home-shopitems-wrapper {
    
    /**
     * Background.
     */

    background-color: $brand-four;

    /**
     * Padding.
     */

    padding-top: 3rem;
    padding-bottom: 4rem;

    /**
     * .home-shopitems-container.
     */

    .home-shopitems-container {

        /**
         * ul.
         */

        ul {

            /**
             * Margin.
             */

            margin-bottom: 2rem;

            /**
             * li.
             */

            li {

                /**
                 * breakpoint.
                 */

                @include breakpoint($up-to-desktop) {

                    /**
                     * 
                     */

                    margin-bottom: 2rem;

                }

                /**
                 * .image-container.
                 */

                .image-container {

                    /**
                     * Position.
                     */

                    position: relative;

                    /**
                     * Content.
                     */

                    content: "";

                    /**
                     * Display.
                     */

                    display: block;

                    /**
                     * Padding.
                     */

                    padding-bottom: 100%;

                }

                /**
                 * .inset.
                 */

                .inset {

                    /**
                     * a.
                     */

                    a {

                        /**
                         * Display.
                         */

                        display: block;

                        /**
                         * Background.
                         */

                        background-color: white;

                        /**
                         * Font related.
                         */

                        text-decoration: none;

                        /**
                         * Transition.
                         */

                        transition: background-color 0.05s linear;

                        /**
                         * &:hover.
                         */

                        &:hover {

                            /**
                             * Background.
                             */

                            background-color: $brand-three;

                            /**
                             * p.
                             */

                            p {

                                /**
                                 * Font related.
                                 */

                                
                            }                           

                        }

                        /**
                         * .image-container.
                         */

                        .image-container {

                            /**
                             * Background.
                             */

                            background-position: center;
                            background-size: cover;

                            /**
                             * img.
                             */

                            img {

                                /**
                                 * Display.
                                 */

                                display: none;

                            }

                        }

                        /**
                         * .details-container.
                         */

                        .details-container {

                            /**
                             * Padding.
                             */

                            padding: 1.5rem 1.5rem 1.5rem 1.5rem;

                            /**
                             * Border.
                             */

                            border: 3px solid $brand-three;
                            border-top: 0;

                            .title {

                                /**
                                 * Margin.
                                 */

                                margin-bottom: 5px;

                                /**
                                 * Font related.
                                 */

                                font-size: 20px;
                                color: $brand-two;

                            }

                            /**
                             * .sub-title.
                             */

                            .sub-title {

                                /**
                                 * Font related.
                                 */
                                
                                font-size: 14px;
                                color: $brand-two;

                            }

                            /**
                             * 
                             * 
                             */

                            .date {

                                /**
                                 * Font related.
                                 */

                                text-transform: uppercase;
                                color: $brand-two;
                                font-weight: 300;
                                font-size: 0.8rem;

                                /**
                                 * Margin.
                                 */

                                margin-bottom: 0.5rem;

                            }

                        }

                    }

                }

            }

        }        

    }

}