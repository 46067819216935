/**
 * .readinglist-list-wrapper.
 */

.readinglist-list-wrapper {

    background-color: $brand-three;

    /**
     * .readinglist-list-container.
     */

    .readinglist-list-container {

        /**
         * .reading-list.
         */

        .reading-list {

            padding-bottom: 4rem;

            /**
             * 
             */

            li {

                margin-bottom: 2.5rem;

                .inset {

                    /**
                     * Background.
                     */

                    background-color: $brand-four;

                    /**
                     * Font related.
                     */

                    line-height: 0;

                    /**
                     * .col-b.
                     */

                    .col-b {

                        /**
                         * Display.
                         */

                        display: flex;
                        
                        /**
                         * Flex.
                         */

                        align-items: center;

                        /**
                         * img.
                         */

                        img {

                            /**
                             * Sizing.
                             */

                            max-width: 100%;                      

                        }

                    }

                    /**
                     * .col-a.
                     */

                    .col-a {

                        /**
                         * @include.
                         */

                        @include breakpoint($up-to-ipad) {

                            /**
                             * Order.
                             */

                            order: 2;                            

                        }

                    }

                    /**
                     * .col-inset.
                     */

                    .col-inset {

                        /**
                         * Padding.
                         */

                        padding-left: 2rem;
                        padding-top: 2rem;
                        padding-right: 2rem;

                        /**
                         * .date.
                         */

                        .date {

                            /**
                             * Opacity.
                             */

                            opacity: 0.5;

                            /**
                             * Padding.
                             */

                            padding-bottom: 1rem;
    
                        }

                        /**
                         * p.
                         */

                        p {

                            /**
                             * Padding.
                             */

                            padding-bottom: 0.5rem;

                            color: $brand-five;

                        }

                        /**
                         * .author.
                         */

                        .author {

                            /**
                             * Opacity.
                             */

                            opacity: 0.8;

                            /**
                             * Padding.
                             */

                            padding-bottom: 1rem;

                        }

                        /**
                         * .heading.
                         */

                        .heading {

                            /**
                             * Padding.
                             */

                            padding-bottom: 0.2rem;

                            /**
                             * Font related.
                             */

                            font-size: 1.25rem;
                            color: $brand-two;

                        }

                        /**
                         * .button.
                         */

                        .button {

                            /**
                             * Margin.
                             */

                            margin-top: 1rem;
                            margin-bottom: 2rem;

                        }

                    }

                }

            }

        }

    }

}