/**
 * .glossary-lists-wrapper.
 */

.glossary-lists-wrapper {

    /**
     * Padding.
     */

    padding-bottom: 4rem;

    /**
     * Background.
     */

    background-color: $brand-three;

    /**
     * .glossary-lists-container.
     */

    .glossary-lists-container {

        text-align: center;

        /**
         * 
         */

        .back-to-top {

            /**
             * Font related.
             */

            text-align: left;

            /**
             * a
             */

            a {



            }

        }

        p.letter {

            /**
             * Display.
             */

            display: inline-block;

            /**
             * Sizing.
             */

            width: 4rem;

            /**
             * Padding.
             */

            padding-top: 1.25rem;
            padding-bottom: 1.25rem;

            /**
             * Margin.
             */

            margin-top: 3rem;
            margin-bottom: 3rem;

            /**
             * Background.
             */

            background-color: $brand-one;

            /**
             * Font related.
             */

            color: $brand-four;
            font-weight: 300;
            font-size: 1.9rem;
            text-transform: uppercase;

        }

        /**
         * 
         */

        .glossary-list {

            /**
             * Margin.
             */

            // margin-bottom: 4rem;

            /**
             * li.
             */

            li {

                /**
                 * Margin.
                 */

                margin-bottom: 2rem;

                /**
                 * &:last-child.
                 */

                &:last-child {
                    margin-bottom: 1rem;
                }

                /**
                 * .inset.
                 */

                .inset {

                    /**
                     * Background.
                     */

                    background-color: $brand-four;

                    /**
                     * Font related.
                     */

                    text-align: left;

                    /**
                     * .col-a.
                     */

                    .col-a {

                        /**
                         * Padding.
                         */

                        padding-bottom: 1.5rem;

                    }

                    /**
                     * .col-b.
                     */

                    .col-b {

                        /**
                         * Display.
                         */

                        display: flex;

                        /**
                         * Flex.
                         */

                        align-items: center;

                        /**
                         * img.
                         */

                        img {

                            /**
                             * Display.
                             */

                            display: block;

                        }

                        /**
                         * @include.
                         */

                        @include breakpoint($up-to-ipad) {

                            /**
                             * Padding.
                             */

                            padding: 2.5rem 2.5rem 1.75rem 2.5rem;

                        }                        

                    }

                    /**
                     * .col-inset.
                     */

                    .col-inset {

                        padding-left: 1.5rem;
                        padding-top: 1.5rem;
                        padding-right: 1.5rem;

                    }

                    /**
                     * .heading.
                     */

                    .heading {

                        /**
                         * Font related.
                         */

                        font-size: 1.25rem;
                        color: $brand-one;
                        letter-spacing: 0.05rem;
                        text-transform: none;
                        text-align: left;

                        /**
                         * Margin.
                         */

                        margin-bottom: 0.5rem;

                    }

                }

                img {
                    max-width: 100%;
                }

            }

        }

    }

}