/**
 * .interviews-interviews-wrapper.
 */

.interviews-interviews-wrapper {

    background-color: $brand-three;
    
    /**
     * .interviews-interviews-container.
     */

    .interviews-interviews-container {

        /**
         * .interview-posts-list.
         */

        .interview-posts-list {

            padding-bottom: 4rem;

            /**
             * li.
             */

            li {

                /**
                 * Margin.
                 */

                margin-bottom: 2.5rem;

                /**
                 * .inset.
                 */

                .inset {

                    /**
                     * Background.
                     */

                    background-color: $brand-four;

                    /**
                     * Font related.
                     */

                    line-height: 0;

                    /**
                     * Sizing. 
                     */

                    height: 100%;

                    /**
                     * 
                     */

                    > .row {

                        /**
                         * 
                         */

                        @include breakpoint($up-to-ipad) {

                        }

                        /**
                         * .col-a.
                         */

                        .col-a {

                            /**
                             * Order.
                             */

                            order: 2;

                        }

                    }

                    /**
                     * .col-inset.
                     */

                    .col-inset {

                        /**
                         * Padding.
                         */

                        padding-left: 2rem;
                        padding-top: 2rem;
                        padding-right: 2rem;

                        /**
                         * .date.
                         */

                        .date {

                            /**
                             * Opacity.
                             */

                            opacity: 0.5;

                            /**
                             * Padding.
                             */

                            padding-bottom: 1rem;
    
                        }

                        /**
                         * p.
                         */

                        p {

                            /**
                             * Padding.
                             */

                            padding-bottom: 0.5rem;

                            color: $brand-five;

                        }

                        /**
                         * .heading.
                         */

                        .heading {

                            /**
                             * Padding.
                             */

                            padding-bottom: 1rem;

                            /**
                             * Font related.
                             */

                            font-size: 1.25rem;
                            color: $brand-two;
                            line-height: 1.6rem;

                        }

                        /**
                         * .button.
                         */

                        .button {

                            /**
                             * Margin.
                             */

                            margin-top: 1rem;
                            margin-bottom: 2rem;

                        }

                    }

                    /**
                     * .image-inset.
                     */

                    .image-inset {

                        /**
                         * Position.
                         */

                        position: relative;

                        /**
                         * Content.
                         */

                        content: "";

                        /**
                         * Display.
                         */

                        display: block;

                        /**
                         * Padding.
                         */

                        padding-bottom: 100%;

                        /**
                         * a.
                         */

                        a {

                            /**
                             * Position.
                             */

                            position: absolute;
                            top: 0;
                            left: 0;

                            /**
                             * Sizing.
                             */

                            width: 100%;
                            height: 100%;

                            /**
                             * Background.
                             */

                            background-position: center;
                            background-size: cover;

                            /**
                             * Display.
                             */

                            display: block;

                        }

                    }

                }

            }

        }

    }

}