/**
 * .buyersguide-headingarea-wrapper.
 */

.buyersguide-headingarea-wrapper {

    /**
     * Padding.
     */

    padding-top: 3rem;
    padding-bottom: 2rem;

    /**
     * .buyersguide-headingarea-container.
     */

    .buyersguide-headingarea-container {

        /**
         * .heading.
         */

        .heading {

            /**
             * Font related.
             */

            text-align: left;

            /**
             * Margin.
             */

            margin-bottom: 0.5rem;

        }

        /**
         * .tagline.
         */

        .tagline {

            /**
             * Font related.
             */

            color: $brand-five;

        }

    }

}
