/**
 * #compendiumapp.
 */

#compendiumapp {

    /**
     * Background.
     */

    background-color: $brand-three;

    /**
     * Padding.
     */

    padding-bottom: 6rem;
    padding-top: 3rem;

    /**
     * .container.
     */

    .container {

        /**
         * Sizing.
         */

        min-height: 30rem;

        /**
         * .row.
         */

        .row {

            /**
             * Sizing.
             */

            min-height: 30rem;

            /**
             * .col-sections.
             */

            .col-sections {

                /**
                 * ul.
                 */
                
                ul {

                    /**
                     * Background.
                     */

                    background-color: $brand-one;
                    
                    /**
                     * li.
                     */

                    li {

                        /**
                         * a.
                         */

                        a {

                            /**
                             * Display.
                             */

                            display: block;

                            /**
                             * Padding.
                             */

                            padding: 1rem;

                            /**
                             * Border.
                             */

                            border-bottom: 1px solid rgba($brand-two, 0.2);
    
                            /**
                             * .name
                             */

                            .name {
    
                                /**
                                 * Font related.
                                 */
    
                                font-weight: 300;
                                text-transform: uppercase;
                                font-size: 1.875rem;
                                line-height: 2.6rem;
                                color: $brand-two;

                            }

                            /**
                             * &:hover.
                             */

                            &:hover {

                                /**
                                 * Font related.
                                 */

                                text-decoration: none;

                                /**
                                 * Background.
                                 */

                                background-color: $brand-four;

                            }

                        }

                    }

                }

            }

            /**
             * .col-options.
             */
    
            .col-options {
                    
                /**
                 * 
                 */

                .options-list {

                    /**
                     * li.
                     */

                    li {

                        /**
                         * .inset.
                         */

                        .inset {

                            background-color: $brand-four;

                            /**
                             * .graphic-container.
                             */

                            .graphic-container {

                                /**
                                 * Padding.
                                 */

                                padding-top: 1rem;
                                padding-bottom: 1rem;

                                /**
                                 * Font related.
                                 */

                                text-align: center;

                                /**
                                 * img.
                                 */

                                img {

                                    /**
                                     * Sizing.
                                     */

                                    max-width: 80%;

                                }

                            }

                            /**
                             * .option-details-container.
                             */

                            .option-details-container {

                                /**
                                 * Border.
                                 */

                                border-top: 1px solid $brand-three;

                                /**
                                 * Padding.
                                 */

                                padding: 1rem 2rem 1rem 1rem;

                                /**
                                 * .name.
                                 */

                                .name {

                                    /**
                                     * .name.
                                     */

                                    line-height: 1.6rem;
                                    font-size: 1.2rem;

                                }
                                

                            }

                        }

                    }

                }

            }

        }

    }

}