/**
 * .page-body-wrapper.
 */

.page-body-wrapper {

    /**
     * Padding.
     */

    padding-top: 2rem;
    padding-bottom: 2rem;

    /**
     * page-body-container.
     */

    .page-body-container {

        /**
         * p.
         */

        p {
            
            /**
             * Font related.
             */

            font-size: 1rem;
            line-height: 1.6rem;
            letter-spacing: 0.03rem;

            /**
             * Margin.
             */

            margin-bottom: 1rem;

        }

        /**
         * h2.
         */

        h2 {

            /**
             * Font related.
             */

            text-align: left;
            text-transform: initial;

            /**
             * Margin.
             */

            margin-bottom: 2rem;

        }

        /**
         * h3.
         */

        h3 {

            /**
             * Margin.
             */

            margin-bottom: 2rem;

            /**
             * Font related.
             */

            font-weight: bold;

        }

        /**
         * h4.
         */

        h4 {

            /**
             * Margin.
             */

            margin-bottom: 2rem;

            /**
             * Font related.
             */

            font-weight: bold;
            
        }

        /**
         * ul.
         */

        ul {

            /**
             * Margin.
             */

            margin-bottom: 2.5rem;

            /**
             * Padding.
             */

            padding-left: 1.1rem;

            /**
             * li.
             */

            li {

                /**
                 * List.
                 */

                list-style: initial;

                /**
                 * Font related.
                 */

                font-size: 1rem;
                line-height: 1.6rem;
                letter-spacing: 0.03rem;

            }

        }

    }

}