/**
 * .blog-intro-wrapper.
 */

.blog-intro-wrapper {

    padding-top: 3rem;
    padding-bottom: 1rem;

    background-color: $brand-three;

    /**
     * .blog-intro-container.
     */

    .blog-intro-container {

        

    }

}