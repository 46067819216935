/**
 * footer
 */

footer {

    /**
     * Padding.
     */

    padding-top: 6rem;
    padding-bottom: 6rem;

    /**
     * @include.
     */

    @include breakpoint($up-to-ipad) {

        /**
         * Padding.
         */

        padding-bottom: 3.5rem;
        padding-top: 3.5rem;

    }

    /**
     * Background.
     */

    background-color: $brand-three;

    /**
     * Position.
     */

    position: relative;

    /**
     * Overflow.
     */

    overflow: hidden;

    /**
     * Font related.
     */

    color: #383838;

    /**
     * img.logo.
     */

    img.logo {

        display: none;

    }

    /**
     * .logo-anchor.
     */

    .logo-anchor {

        /**
         * Display.
         */

        display: inline-block !important;

    }

    /**
     * .logo.
     */

    .logo {

        /**
         * Display.
         */

        display: block;

        /**
         * Margin.
         */

        margin-bottom: 1rem;

        /**
         * Sizing.
         */

        width: 9rem;
        max-width: 9rem;

        /**
         * path.
         */

        path {

            /**
             * fill.
             */

            fill: rgba(#383838, 0.8);

        }

    }

    /**
     * img.background-logo.
     */

    img.background-logo {

        /**
         * Display.
         */

        display: none;

    }
    
    /**
     * .col-logo.
     */

    .col-logo {

        /**
         * Padding.
         */

        padding-top: 2rem;

        /**
         * @include.
         */

        @include breakpoint($up-to-ipad) {

            /**
             * Flex.
             */

            order: 3;

        }

        /**
         * p.
         */

        p {

            /**
             * Font related.
             */

            color: $brand-five;
            font-size: 0.8rem;

        }

        /**
         * a.
         */

        a {

            /**
             * Font related.
             */

            color: $brand-five;

            /**
             * &:hover.
             */

            &:hover {

                color: $brand-one;

            }

        }

    }

    /**
     * .col-links.
     */

    .col-links {

        /**
         * Padding.
         */

        padding-top: 2rem;

        /**
         * @include.
         */

        @include breakpoint($up-to-ipad) {

            /**
             * Padding.
             */

            padding-top: 0;

        }

        /**
         * ul.
         */

        ul {

            /**
             * li.
             */

            li {

                /**
                 * Margin.
                 */

                margin-bottom: 0.8rem;

                /**
                 * &.current-menu-item,
                 * &.active-subpage.
                 */

                &.current-menu-item,
                &.active-subpage {

                    /**
                     * a.
                     */

                    a {

                        /**
                         * Font related.
                         */

                        font-weight: bold;

                    }

                }

                /**
                 * a.
                 */

                a {

                    /**
                     * Font related.
                     */

                    color: $brand-two;
                    text-transform: uppercase;
                    text-decoration: none;
                    font-weight: 300;
                    letter-spacing: 0.05rem;

                    /**
                     * &:hover.
                     */

                    &:hover {

                        /**
                         * Opacity.
                         */

                        opacity: 0.7;

                    }

                }

            }

        }

    }

    /**
     * 
     */

    .background-logo {

        /**
         * Position.
         */

        position: absolute;
        top: 50%;
        right: 0;
        
        /**
         * Transform.
         */

        transform: translateY(-50%) translateX(60%);

        /**
         * Sizing.
         */

        height: 200%;

        /**
         * Z-index.
         */

        z-index: 0;

        /**
         * path.
         */

        path {
            
            /**
             * Fill.
             */

            fill: #f7f7f7;

        }

    }

    /**
     * 
     */

    &.style-a {

        background-color: $brand-four;

        /**
         * .background-logo.
         */

        .background-logo {

            /**
             * path.
             */

            path {
                
                /**
                 * Fill.
                 */

                fill: #fafafa;
                
            }

        }

    }

    /**
     * 
     */

    &.style-b {

        /**
         * Background.
         */

        background-color: $brand-two;

        /**
         * Font related.
         */

        color: #2d2d2d;

        /**
         * .col-links.
         */

        .col-links {

            /**
             * ul.
             */

            ul {
                
                /**
                 * li.
                 */

                li {

                    /**
                     * &.current-menu-item,
                     * &.active-subpage.
                     */

                    &.current-menu-item,
                    &.active-subpage {

                        /**
                         * a.
                         */

                        a {

                            /**
                             * Font related.
                             */

                            font-weight: bold;

                        }

                    }

                    /**
                     * a.
                     */

                    a {

                        color: #2d2d2d;

                        &:hover {

                            opacity: 0.7;

                        }

                    }

                }

            }

        }

        /**
         * .logo.
         */

        .logo {

            /**
             * path.
             */

            path {

                fill: #3b3b3b;

            }

        }

        /**
         * .background-logo.
         */

        .background-logo {

            /**
             * path.
             */

            path {
                
                /**
                 * Fill.
                 */

                fill: rgba(#3b3b3b, 0.1);
                
            }

        }

    }

}
