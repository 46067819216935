/**
 * .readinglist-intro-wrapper.
 */

.readinglist-intro-wrapper {

    padding-top: 3rem;
    padding-bottom: 1rem;

    background-color: $brand-three;

    /**
     * .readinglist-intro-container.
     */

    .readinglist-intro-container {

        

    }

}