// Remember: do NOT edit this file.

$brand-four: #ffffff;

$brand-five: #707070;

$brand-three: #f3f3f3;

$brand-two: #4c4c4c;

$brand-one: #d2c7a8;
