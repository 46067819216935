/**
 * .mobilenav-wrapper.
 */

.mobilenav-wrapper {

    /**
     * Display.
     */

    display: block;

    /**
     * Position.
     */

    position: fixed;
    top: 0;
    right: 0;   

    /**
     * Sizing.
     */

    width: 100%;
    height: 100vh;

    /**
     * Opacity.
     */

    opacity: 0;

    /**
     * 
     */

    pointer-events: none;

    /**
     * .hiding.
     */

    &.hiding {

        opacity: 1;

    }

    /**
     * Background.
     */

    background: rgba(#272424, 0);

    /**
     * 
     */

    transition: background 0.3s ease-out;

    /**
     * @include.
     */

    @include breakpoint(max-width 500px) {

        /**
         * Sizing.
         */

        width: 100%;

    }

    /**
     * Z-index.
     */

    z-index: 10;

    /**
     * &.show.
     */

    &.show {

        /**
         * Background.
         */

        background: rgba(#272424, 0.95);

        /**
         * Opacity.
         */

        opacity: 1;

        /**
         * Pointer events.
         */

        pointer-events: all;

        /**
         * .mobilenav-container.
         */

        .mobilenav-container {

            right: 0;

        }
        
        .logo {

            opacity: 1;

        }

    }

    /**
     * .mobilenav-container.
     */

    .mobilenav-container {

        /**
         * Position.
         */

        position: fixed;
        right: -100%;
        top: 0;       

        /**
         * Sizing.
         */

        width: 20rem;
        height: 100%;

        /**
         * Padding.
         */

        padding-top: 4rem;

        /**
         * Transition.
         */

        transition: right 0.4s ease-out;

        /**
         * Background.
         */

        background-color: $brand-two;

        /**
         * .close-button.
         */

        .close-button {

            /**
             * Position.
             */

            position: absolute;
            top: 1.25rem;
            right: 1.25rem;

            /**
             * Sizing.
             */
        
            width: 1.5rem;

            /**
             * &:hover.
             */

            &:hover {

                /**
                 * svg.
                 */

                svg {

                    /**
                     * polygon.
                     */

                    polygon {

                        /**
                         * Fill.
                         */

                        fill: $brand-one;

                    }

                }

            }

            /**
             * svg.
             */

            svg {

                /**
                 * Sizing.
                 */

                width: 1.5rem;

                /**
                 * polygon.
                 */

                polygon {

                    /**
                     * Fill.
                     */

                    fill: $brand-four;

                }

            }

        }

        /**
         * .logo.
         */

        .logo {

            /**
             * Position.
             */

            position: absolute;
            top: 1.25rem;
            left: 1.25rem;

            /**
             * Sizing.
             */
        
            width: 3rem;

            /**
             * Opacity.
             */

            opacity: 0.6;

        }

        /**
         * .primary-menu.
         */

        .primary-menu {

            /**
             * Border.
             */

            border-top: 1px solid rgba($brand-five, 0.4);

            /**
             * li.
             */

            li {

                /**
                 * Display.
                 */

                display: block;

                /**
                 * Border.
                 */

                border-bottom: 1px solid rgba($brand-five, 0.4);

                /**
                 * &.current-menu-item,
                 * &.active-subpage.
                 */

                &.current-menu-item,
                &.active-subpage {

                    /**
                     * a.
                     */

                    a {

                        /**
                         * Font related.
                         */

                        font-weight: bold;

                        /**
                         * Background.
                         */

                        background-color: #404040;

                    }                       

                }

                /**
                 * a.
                 */

                a {

                    /**
                     * Display.
                     */

                    display: block;

                    /**
                     * Font related.
                     */

                    font-size: 0.875rem;
                    text-transform: uppercase;
                    color: $brand-three;
                    letter-spacing: 0.05rem;

                    /**
                     * Padding.
                     */

                    padding: 1.25rem 1rem 1.25rem 1.5rem;

                    /**
                     * &:hover.
                     */

                    &:hover {

                        /**
                         * Background.
                         */

                        background-color: #404040;

                    }


                }

            }

        }

    }

}