/**
 * .compendium-intro-wrapper.
 */

.compendium-intro-wrapper {

    /**
     * Padding.
     */

    padding-top: 3rem;
    padding-bottom: 0rem;

    /**
     * Background.
     */

    background-color: $brand-three;

    /**
     * .compendium-intro-container.
     */

    .compendium-intro-container {

    }

}