/**
 * .maps-maps-wrapper.
 */

.maps-maps-wrapper {

    /**
     * Background.
     */

    background-color: $brand-one;

    /**
     * Padding.
     */

    padding-top: 3.5rem;
    padding-bottom: 3.5rem;

    /**
     * .maps-maps-container.
     */

    .maps-maps-container {

        /**
         * h2.
         */

        h2 {

            /**
             * Color.
             */

            color: $brand-four;

            /**
             * Margin.
             */
        
            margin-bottom: 0.5rem;

        }

        /**
         * .maps.
         */

        .maps {

            /**
             * .map-info.
             */

            &.map-info {

                /**
                 * .map.
                 */

                .map {

                    /**
                     * Margin.
                     */

                    margin-bottom: 2rem;

                }

            }

            /**
             * Padding.
             */

            padding-top: 5rem;

            /**
             * .map-slide.
             */

            .map {

                /**
                 * Display.
                 */

                display: flex;

                /**
                 * breakpoint.
                 */

                @include breakpoint($up-to-ipad) {

                    /**
                     * Display.
                     */

                    display: block;

                }

                /**
                 * Margin.
                 */

                margin-bottom: 6rem;

                /**
                 * &:nth-child(even).
                 */

                &:nth-child(even) {

                    flex-direction: row-reverse;

                }

                /**
                 * .col-a.
                 */

                .col-a {

                    /**
                     * .inset.
                     */

                    .inset {

                    }

                    /**
                     * img.
                     */

                    img {

                        max-width: 100%;

                    }

                    /**
                     * breakpoint.
                     */

                    @include breakpoint($up-to-ipad) {

                        width: 100%;

                        padding-right: 0;

                    }

                    /**
                     * Sizing.
                     */

                    width: 41%;

                    /**
                     * Padding.
                     */

                    padding-right: 15px;

                }

                /**
                 * .col-b.
                 */

                .col-b {

                    /**
                     * Padding.
                     */

                    padding-right: 2rem;

                    /**
                     * .inset.
                     */

                    .inset {

                    }

                    /**
                     * Padding.
                     */

                    padding-left: 15px;

                    /**
                     * Sizing.
                     */

                    width: 59%;

                    /**
                     * breakpoint.
                     */

                    @include breakpoint($up-to-ipad) {

                        width: 100%;

                    }

                    /**
                     * .pin.
                     */

                    .pin {

                        /**
                         * Margin.
                         */

                        margin-top: 2rem;
                        margin-bottom: 0.8rem;

                        /**
                         * Sizing.
                         */

                        max-width: 1rem;

                    }

                    /**
                     * .country.
                     */

                    .country {

                        /**
                         * Font related.
                         */

                        text-transform: uppercase;
                        font-weight: bold;
                        color: rgba($brand-two, 0.6);

                        /**
                         * Margin.
                         */

                        margin-bottom: 0.4rem;

                    }

                    /**
                     * .location.
                     */

                    .location {

                        /**
                         * Font related.
                         */

                        font-size: 1.875rem;
                        color: $brand-four;
                        // font-weight: bold;

                    }

                    /**
                     * .divider.
                     */

                    .divider {

                        /**
                         * Display.
                         */

                        display: block;

                        /**
                         * Sizing.
                         */

                        height: 2px;
                        width: 3.5rem;

                        /**
                         * Background.
                         */

                        background-color: rgba($brand-two, 0.2);

                        /**
                         * Margin.
                         */

                        margin-top: 1.3rem;
                        margin-bottom: 1.4rem;

                    }

                    /**
                     * .teaser.
                     */

                    .teaser {

                        /**
                         * Margin.
                         */

                        margin-bottom: 2rem;

                        /**
                         * Font related.
                         */

                        color: $brand-four;

                    }

                }

            }

        }

    }

}