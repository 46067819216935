/**
 * .button.
 */

.button {

    /**
     * Display.
     */

    display: inline-block;

    /**
     * Background.
     */

    background-color: pink;

    /**
     * Padding.
     */

    padding: 1.25rem;

    /**
     * Font related.
     */

    text-transform: uppercase;
    letter-spacing: 0.05rem;

    /**
     * Transition.
     */

    transition: background-color 0.05s linear;

}

/**
 * 
 */

.button-a {

    /**
     * Background.
     */

    background-color: $brand-two;

    /**
     * Font related.
     */

    color: $brand-four;
    
    /**
     * &:hover.
     */

    &:hover {

        /**
         * Font related.
         */

        text-decoration: none;

        /**
         * Background.
         */

        background-color: $brand-one;

        /**
         * 
         */

        color: $brand-two;

    }

}

/**
 * 
 */

.button-b {

    /**
     * Background.
     */

    background-color: $brand-four;

    /**
     * Font related.
     */

    color: $brand-two;
    
    /**
     * &:hover.
     */

    &:hover {

        /**
         * Font related.
         */

        text-decoration: none;

        /**
         * Background.
         */

        background-color: $brand-two;

        /**
         * 
         */

        color: $brand-four;

    }

}

/**
 * 
 */

.button-c {

    /**
     * Background.
     */

    background-color: $brand-one;

    /**
     * Font related.
     */

    color: $brand-four;
    
    /**
     * &:hover.
     */

    &:hover {

        /**
         * Font related.
         */

        text-decoration: none;

        /**
         * Background.
         */

        background-color: $brand-two;

        /**
         * 
         */

        color: $brand-four;

    }

}

/**
 * .button-container.
 */

.button-container {

    /**
     * &.central.
     */

    &.central {

        text-align: center;

    }

}