/**
 * .map-wrapper.
 */

.map-wrapper {

    /**
     * Position.
     */

    position: relative;

    /**
     * .store-list-toggle.
     */

    .store-list-toggle {

        /**
         * Sizing.
         */

        width: 3.6rem;
        height: 3.6rem;

        /**
         * Background.
         */

        background-color: $brand-two;
        
        /**
         * 
         */

        &.active {

            background-color: $brand-one;

        }

        /**
         * Position.
         */

        position: absolute;
        bottom: 2rem;
        left: 2rem;

        /**
         * Z-index.
         */

        z-index: 5;

        /**
         * Border.
         */

        border-radius: 3rem;

        /**
         * img.
         */

        img {

            /**
             * Position.
             */

            position: absolute;
            top: 50%;
            left: 50%;

            /**
             * Sizing.
             */

            width: 2rem;

            /**
             * Transform.
             */

            transform: translateX(-50%) translateY(-50%);

        }

    }

    /**
     * .map-container.
     */

    .map-container {

        /**
         * 
         */

        position: relative;

        /**
         * 
         */

        #map {

            min-height: 80vh;

        }

        /**
         * #storesapp.
         */

        #storesapp {

            /**
             * Position.
             */

            position: absolute;
            top: 50%;
            right: 4rem;

            /**
             * Transform.
             */

            transform: translateY(-50%);

            /**
             * Overflow.
             */

            overflow: hidden;

            /**
             * Sizing.
             */

            width: 20rem;

            /**
             * Shadow.
             */

            box-shadow: 1px 1px 5px rgba(#888888, 0.5);

            /**
             * Z-index.
             */

            z-index: 8;

            /**
             * 
             */

            &.hide {

                opacity: 0;

                pointer-events: none;

            }

            /**
             * breakpoint.
             */

            @include breakpoint(max-width 500px) {

                /**
                 * Position.
                 */

                right: auto;
                left: 50%;

                /**
                 * Transform.
                 */

                transform: translateY(-50%) translateX(-50%);

            }

            /**
             * &.showDetails.
             */

            &.showDetails {

                /**
                 * .wrapper.
                 */

                .wrapper {

                    /**
                     * Transform.
                     */

                    transform: translateX(-50%);

                }

            }

            /**
             * 
             */

            .wrapper {

                /**
                 * Display.
                 */

                display: flex;

                /**
                 * Sizing.
                 */

                height: 30rem;
                width: 40rem;

                /**
                 * breakpoint.
                 */

                @include breakpoint(max-width 500px) {

                    /**
                     * Sizing.
                     */

                    height: 20rem;

                }

                /**
                 * Transition.
                 */

                transition: transform 0.2s ease;

                /**
                 * .store-list-container.
                 */

                .store-list-container {
                
                    /**
                     * Display.
                     */

                    display: inline-block;
                    
                    /**
                     * Sizing.
                     */

                    width: 20rem;

                    /**
                     * 
                     */

                    background-color: $brand-four;

                    /**
                     * Position.
                     */

                    position: relative;

                    /**
                     * .heading-container.
                     */

                    .heading-container {

                        /**
                         * Background.
                         */

                        background-color: $brand-two;

                        /**
                         * Padding.
                         */

                        padding-top: 1rem;
                        padding-bottom: 1rem;

                        /**
                         * Position.
                         */

                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;

                        /**
                         * Sizing.
                         */

                        height: 3.1rem;

                        /**
                         * .heading.
                         */

                        .heading {

                            /**
                             * Font related.
                             */

                            font-size: 1rem;
                            font-weight: bold;
                            text-transform: uppercase;
                            text-align: center;
                            color: $brand-four;

                        }

                    }

                    /**
                     * .scrollable.
                     */

                    .scrollable {

                        /**
                         * Padding.
                         */

                        padding-top: 3.1rem;

                        /**
                         * Overflow.
                         */

                        overflow: scroll;
                        overflow-x: scroll;

                        /**
                         * 
                         */

                        height: 100%;

                        /**
                         * .stores-list.
                         */

                        .stores-list {

                            /**
                             * li.
                             */

                            li {

                                /**
                                 * a.
                                 */

                                a {

                                    /**
                                     * Display.
                                     */

                                    display: flex;

                                    /**
                                     * Padding.
                                     */

                                    padding-top: 0.5rem;
                                    padding-bottom: 0.5rem;

                                    /**
                                     * Border.
                                     */

                                    border-bottom: 1px solid $brand-three;

                                    /**
                                     * Background.
                                     */

                                    background-color: $brand-four;

                                    /**
                                     * &:hover.
                                     */

                                    &:hover {

                                        /**
                                         * Background.
                                         */

                                        background-color: $brand-three;

                                        /**
                                         * Font related.
                                         */

                                        text-decoration: none;

                                    }

                                    /**
                                     * .col-logo.
                                     */

                                    .col-logo {

                                        /**
                                         * Padding.
                                         */

                                        padding-left: 10px;
                                        padding-right: 10px;

                                        /**
                                         * Sizing.
                                         */

                                        min-width: 9rem;
                                        max-width: 9rem;
                                        
                                        /**
                                         * img.
                                         */

                                        img {

                                            /**
                                             * Sizing.
                                             */

                                            max-width: 100%;

                                        }

                                    }

                                    /**
                                     * .col-name.
                                     */

                                    .col-name {

                                        /**
                                         * Display.
                                         */

                                        display: flex;

                                        /**
                                         * Flex.
                                         */

                                        flex-direction: column;
                                        align-self: center;

                                    }

                                    /**
                                     * .col-link.
                                     */

                                    .col-link {

                                        /**
                                         * Display.
                                         */

                                        display: flex;

                                        /**
                                         * Flex.
                                         */

                                        flex-direction: column;
                                        align-self: center;
                                        align-items: flex-end;
                                        flex-grow: 1;

                                        /**
                                         * Padding.
                                         */

                                        padding-right: 1rem;

                                        /**
                                         * img.
                                         */

                                        img {

                                            /**
                                             * Sizing.
                                             */

                                            width: 1rem;                                          

                                        }

                                    }

                                    /**
                                     * .storetype.
                                     */

                                    .storetype {

                                        display: block;

                                        /**
                                         * Font related.
                                         */

                                        text-transform: uppercase;
                                        color: $brand-five;
                                        font-size: 0.8rem;

                                    }

                                    /**
                                     * .storename.
                                     */

                                    .storename {

                                        /**
                                         * Display.
                                         */

                                        display: block;

                                        /**
                                         * Font related.
                                         */

                                        font-size: 1.3rem;
                                        color: $brand-two;
                                        line-height: 1.4rem;

                                    }

                                }

                            }

                        }

                    }



                }

                /**
                 * .store-details-container.
                 */

                .store-details-container {

                    /**
                     * Display.
                     */

                    display: inline-block;

                    /**
                     * Position.
                     */

                    position: relative;

                    /**
                     * Sizing.
                     */

                    width: 20rem;

                    /**
                     * Background.
                     */
                
                    background-color: $brand-four;

                    /**
                     * .heading-container.
                     */

                    .heading-container {

                        /**
                         * Background.
                         */

                        background-color: $brand-one;

                        /**
                         * Padding.
                         */

                        padding-top: 1rem;
                        padding-bottom: 1rem;

                        /**
                         * Position.
                         */
                    
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;

                        /**
                         * Sizing.
                         */

                        height: 3.1rem;

                        /**
                         * img.arrow-left.
                         */

                        img.arrow-left {

                            /**
                             * Position.
                             */

                            position: absolute;
                            top: 50%;
                            left: 1rem;

                            /**
                             * Transform.
                             */

                            transform: translateY(-50%);

                            /**
                             * Sizing.
                             */

                            width: 1rem;

                            /**
                             * Cursor.
                             */
                        
                            cursor: pointer;

                        }

                        /**
                         * .heading.
                         */

                        .heading {

                            /**
                             * Font related.
                             */

                            font-size: 1rem;
                            font-weight: bold;
                            text-transform: uppercase;
                            text-align: center;
                            color: $brand-four;

                        }

                        /**
                         * .storetype.
                         */

                        .storetype {

                            display: inline-block;

                            position: absolute;
                            top: 50%;
                            right: 1rem;

                            font-size: 0.8rem;
                            text-transform: uppercase;
                            color: $brand-four;

                            opacity: 0.5;

                            transform: translateY(-30%);

                            


                        }

                    }

                    .scrollable {

                        /**
                         * Padding.
                         */

                        padding-top: 3.1rem;

                        /**
                         * Overflow.
                         */

                        overflow: scroll;

                        /**
                         * 
                         */

                        height: 100%;

                        /**
                         * .image-container.
                         */

                        .image-container {

                            height: 11rem;

                            background-position: center;
                            background-size: cover;

                        }

                        /**
                         * .logo-container.
                         */

                        .logo-container {

                            background-color: $brand-three;

                            text-align: center;

                            img {

                                display: inline-block;

                                max-width: 50%;

                            }

                        }

                        /**
                         * .detail-container.
                         */

                        .detail-container {

                            /**
                             * Padding.
                             */

                            padding-top: 1rem;
                            padding-left: 1.5rem;
                            padding-right: 1.5rem;
                            padding-bottom: 1rem;

                        }

                        /**
                         * .store-description-container.
                         */

                        .store-description-container {

                            /**
                             * Border.
                             */

                            border-bottom: 1px solid $brand-three;

                        }

                        /**
                         * .address-container.
                         */

                        .address-container {
                            
                            /**
                             * Border.
                             */

                            border-bottom: 1px solid $brand-three;

                        }

                        /**
                         * .telephone-container.
                         */

                        .telephone-container {
                            
                        }

                        /**
                         * .address-container,
                         * .telephone-container.
                         */

                        .address-container,
                        .telephone-container {

                            /**
                             * Display.
                             */

                            display: flex;

                            /**
                             * .col-name.
                             */

                            .col-name {

                                /**
                                 * Flex.
                                 */

                                flex-basis: 30%;

                                /**
                                 * Font related.
                                 */

                                font-weight: bold;

                            }

                            /**
                             * .col-value.
                             */

                            .col-value {

                            }

                        }

                    }

                }

            }



        }

    }

}