/**
 * header.header
 */

header.header {

    /**
     * Sizing.
     */

    min-height: 79px;
    width: 100%;
    
    /**
     * Position.
     */

    position: fixed;
    top: 0;

    /**
     * Padding.
     */

    padding-top: 1rem;
    padding-bottom: 1rem;

    /**
     * 
     */

    background-color: white;

    /**
     * Border.
     */

    border-bottom: 1px solid $brand-three;

    /**
     * Z-index.
     */

    z-index: 9;

    /**
     * .logo-anchor.
     */

    .logo-anchor {

        /**
         * Position,
         */

        position: absolute;
        top: 50%;
        left: 2rem;

        /**
         * Transform.
         */

        transform: translateY(-50%);

        /**
         * Display.
         */

        display: block;

        /**
         * Z-index.
         */

        z-index: 10;

        /**
         * Sizing.
         */

        // width: 4rem;
        width: 5rem;

    }

    /**
     * 
     */

    .menu-primary-menu-container {

        /**
         * Font related.
         */

        text-align: center;

        /**
         * ul.
         */

        ul {

            /**
             * Display.
             */

            display: inline-block;

            /**
             * @include.
             */

            @include breakpoint($up-to-desktop) {
                display: none;
            }

            /**
             * li.
             */

            li {

                /**
                 * Padding.
                 */

                padding: 1rem 0.7rem 1rem 0.7rem;

                /**
                 * Display.
                 */

                display: inline-block;

                /**
                 * &.current_page_item.
                 */

                &.current_page_item {

                    /**
                     * a.
                     */

                    a {

                        /**
                         * Font related.
                         */

                        font-weight: bold;

                    }

                }

                /**
                 * &.active-subpage.
                 */

                &.active-subpage {

                    /**
                     * a.
                     */

                    a {

                        /**
                         * Font related.
                         */

                        font-weight: bold;

                    }

                }
                
                /**
                 * a.
                 */

                a {
                    
                    /**
                     * Font related.
                     */

                    color: $brand-two;
                    text-transform: uppercase;
                    letter-spacing: 0.05rem;

                    /**
                     * 
                     */

                    &:hover {
                        
                        text-decoration: none;
                        opacity: 0.8;

                    }

                }

            }

        }

    }

    /**
     * .hamburger-button-wrapper.
     */

    .hamburger-button-wrapper {

        /**
         * Position.
         */

        position: absolute;
        right: 1rem;

        /**
         * Margin.
         */

        margin-right: 3rem;

        /**
         * Display.
         */

        display: none;


        /**
         * @include.
         */

        @include breakpoint($up-to-ipad) {

            margin-right: 1rem;

        }

        /**
         * @include.
         */

        @include breakpoint($up-to-desktop) {

            /**
             * Display.
             */
        
            display: inline-block;

        }
        
        /**
         * .hamburger-button.
         */

        .hamburger-button {

            /**
             * Background.
             */

            background-color: white;

            /**
             * Padding.
             */

            padding: 0.8rem 0.5rem 0.8rem 0.5rem;

            /**
             * Display.
             */

            display: inline-block;

            /**
             * svg.
             */

            svg {

                width: 2rem;

                path {
    
                    fill: $brand-two;

                }

            }

            /**
             * &:hover.
             */

            &:hover {

                svg {

                    path {
    
                        fill: $brand-five;
    
                    }
    
                }

            }

        }

    }

}