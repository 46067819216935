/**
 * .brands-intro-wrapper.
 */

.brands-intro-wrapper {

    /**
     * Padding.
     */

    padding-top: 3rem;
    padding-bottom: 0rem;

    /**
     * Background.
     */

    background-color: $brand-three;

    /**
     * .brands-intro-container.
     */

    .brands-intro-container {

    }

}