/**
 * .contact-form-wrapper.
 */

.contact-form-wrapper {

    /**
     * Background.
     */

    background-color: $brand-two;

    /**
     * Padding.
     */

    padding-top: 3rem;
    padding-bottom: 3rem;

    /**
     * .contact-form-container.
     */

    .contact-form-container {

        h2 {

            color: $brand-four;

        }

        .wpforms-container {

            padding-top: 2rem;

            /**
             * .wpforms-field-label.
             */

            .wpforms-field-label {

                /**
                 * Font related.
                 */

                color: #afafaf;
                font-weight: 300 !important;
                text-transform: uppercase;
                font-size: 1.125rem !important;

                /**
                 * Margin.
                 */

                padding-bottom: 0.5rem !important;

                /**
                 * .wpforms-required-label.
                 */

                .wpforms-required-label {

                    color: #afafaf;

                }

            }

            /**
             * 
             */

            input[type="text"],
            input[type="email"],
            textarea {

                width: 100%;
                max-width: 100% !important;

                background-color: $brand-two !important;

                border: 0 !important;

                border-bottom: 1px solid $brand-five !important;

                color: $brand-four !important;
                font-size: 1.25rem !important;
                letter-spacing: 0.03rem;

                padding-left: 0 !important;
                

            }

            textarea {

                

            }

            .wpforms-error {

                padding-top: 1rem;
                color: $brand-four !important;
                font-size: 1.25rem !important;

            }

            .wpforms-field {

                margin-bottom: 3rem;

            }

            #wpforms-187-field_5-container {

                margin-bottom: 1rem;

            }

            .wpforms-field-checkbox {

                margin-bottom: 1rem;

                label {

                    color: #afafaf;
                    font-size: 0.9rem !important;

                }

            }

            .wpforms-recaptcha-container {

                margin-bottom: 1rem;

            }

            .wpforms-submit-container {

                text-align: center;

                button {

                    display: inline-block;
                    
                    border: 0 !important;
                    
                    background-color: $brand-four;

                    color: $brand-two;
                    text-transform: uppercase;
                    letter-spacing: 0.1rem;

                    padding: 1rem;

                    transition: background-color 0.1s linear;

                    &:hover {

                        background-color: $brand-one;

                        color: $brand-four;

                    }

                }

            }

        }

    }

}