/**
 * .home-compendiumbrands-wrapper.
 */

.home-compendiumbrands-wrapper {

    /**
     * Padding.
     */

    padding-top: 4rem;
    padding-bottom: 4rem;

    /**
     * .home-compendiumbrands-container.
     */

    .home-compendiumbrands-container {

        /**
         * .brand-slider.
         */

        .brand-slider {

            /**
             * Position.
             */

            position: relative;

            /**
             * Margin.
             */

            margin-bottom: 1rem;

            /**
             * 
             */

            .slick-arrow {

                /**
                 * Position.
                 */

                position: absolute;

                /**
                 * Z-index.
                 */

                z-index: 10;

                /**
                 * 
                 */

                border: 0;

                /**
                 * Sizing.
                 */

                height: 2rem;
                width: 2rem;

                /**
                 * Font related.
                 */

                text-indent: -9999px;

                /**
                 * Cursor.
                 */

                cursor: pointer;

                /**
                 * Background.
                 */

                background-repeat: no-repeat;
                background-position: center;

                /**
                 * 
                 */

                &.slick-prev {

                    /**
                     * Position.
                     */

                    top: 50%;
                    left: -1rem;

                    /**
                     * Transform.
                     */

                    transform: translateY(-50%) translateX(-100%);

                    /**
                     * 
                     */

                    background-image: url("/assets/svg/common/left-arrow.svg");

                }

                /**
                 * 
                 */

                &.slick-next {

                    /**
                     * Position.
                     */

                    top: 50%;
                    right: -1rem;

                    /**
                     * Transform.
                     */

                    transform: translateY(-50%) translateX(100%);
                    
                    /**
                     * 
                     */

                    background-image: url("/assets/svg/common/right-arrow.svg");

                }

            }

            /**
             * .brand-slide.
             */

            .brand-slide {

                /**
                 * .compendium-brand-list.
                 */

                .compendium-brand-list {

                    /**
                     * Display.
                     */

                    display: flex;

                    /**
                     * li.
                     */

                    li {

                        /**
                         * Flex.
                         */

                        flex-basis: 33.33%;

                        /**
                         * Font related.
                         */

                        text-align: center;

                        /**
                         * a.
                         */

                        a {

                            /**
                             * Display.
                             */

                            display: block;

                            /**
                             * img.
                             */

                            img {

                                /**
                                 * Sizing.
                                 */

                                max-width: 80%;

                                /**
                                 * Display.
                                 */

                                display: inline-block;

                                /**
                                 * Opacity.
                                 */

                                opacity: 0.5;

                            }

                        }

                    }

                }

            }

        }

    }

}