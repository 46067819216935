/**
 * .blogpost-content-wrapper.
 */

.blogpost-content-wrapper {

    /**
     * Padding.
     */

    padding-top: 2rem;
    padding-bottom: 2rem;

    /**
     * blogpost-content-container.
     */

    .blogpost-content-container {

        /**
         * .caption-container.
         */

        .caption-container {
           
            /**
             * p.
             */

            p {

                /**
                 * Padding.
                 */

                padding-top: 0.1rem;
                padding-bottom: 0 !important;

                /**
                 * Font related.
                 */

                font-size: 0.8rem;
                color: $brand-five;

            }

            /**
             * Margin.
             */

            margin-bottom: 2.5rem;

        }

        /**
         * .row-blogpost-block.
         */

        .row-blogpost-block {

            /**
             * p.
             */

            p {

                /**
                 * Padding.
                 */

                padding-bottom: 2rem;

                /**
                 * Font related.
                 */

                color: $brand-two;

            }

        }

        /**
         * .row-blogpost-block-image.
         */

        .row-blogpost-block-image {

            /**
             * img.
             */

            img {

                max-width: 100%;



                // margin-bottom: 2.5rem;

            }

        }

        /**
         * .row-blogpost-block-video.
         */

        .row-blogpost-block-video {

            /**
             * Margin.
             */

            // margin-bottom: 2.5rem;

            /**
             * .embed-container.
             */

            .embed-container { 
                position: relative; 
                padding-bottom: 56.25%;
                overflow: hidden;
                max-width: 100%;
                height: auto;
            } 
        
            .embed-container iframe,
            .embed-container object,
            .embed-container embed { 
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }

            /**
             * .caption-container.
             */

            .caption-container {

                /**
                 * p.
                 */

                p {

                    /**
                     * Padding.
                     */

                    padding-top: 0.3rem;

                }

            }

        }


        /**
         * .row-blogpost-block-text.
         */

        .row-blogpost-block-text {

            /**
             * p.
             */

            p {
                
                /**
                 * Font related.
                 */

                font-size: 1rem;
                line-height: 1.6rem;
                letter-spacing: 0.03rem;

            }

            /**
             * h2.
             */

            h2 {

                /**
                 * Font related.
                 */

                text-align: left;
                text-transform: initial;
                font-weight: bold;
                font-size: 1.3rem;

                /**
                 * Margin.
                 */

                margin-bottom: 2rem;


            }

            /**
             * h3.
             */

            h3 {

                /**
                 * Margin.
                 */

                margin-bottom: 2rem;

                /**
                 * Font related.
                 */

                font-weight: bold;
                font-size: 1.2rem;

            }

            /**
             * h4.
             */

            h4 {

                /**
                 * Margin.
                 */

                margin-bottom: 2rem;
                font-size: 1.1rem;

                /**
                 * Font related.
                 */

                font-weight: bold;
                
            }

            /**
             * ul.
             */

            ul {

                /**
                 * Margin.
                 */

                margin-bottom: 2.5rem;

                /**
                 * Padding.
                 */

                padding-left: 1.1rem;

                /**
                 * li.
                 */

                li {

                    /**
                     * List.
                     */

                    list-style: initial;

                    /**
                     * Font related.
                     */

                    font-size: 1rem;
                    line-height: 1.6rem;
                    letter-spacing: 0.03rem;

                }

            }


        }

    }

}

/**
 * .blogpost-backbutton-wrapper.
 */

.blogpost-backbutton-wrapper {

    /**
     * .blogpost-backbutton-container.
     */

    .blogpost-backbutton-container {

        padding-top: 2rem;
        padding-bottom: 4rem;

    }

}