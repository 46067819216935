/**
 * .map-moremapslist-wrapper.
 */

.map-moremapslist-wrapper {
   
    /**
     * Background.
     */

    background-color: $brand-two;

    /**
     * Padding.
     */

    padding-top: 3.5rem;
    padding-bottom: 3.5rem;

    /**
     * .map-moremapslist-container.
     */

    .map-moremapslist-container {

        /**
         * h2.
         */

        h2 {

            /**
             * Color.
             */

            color: $brand-four;

            /**
             * Margin.
             */
        
            margin-bottom: 0.5rem;

        }

        /**
         * .maps.
         */

        .maps {

            /**
             * Padding.
             */

            padding-top: 3rem;

            /**
             * .map-slide.
             */

            .map {

                /**
                 * Display.
                 */

                display: flex;

                /**
                 * Margin.
                 */

                margin-bottom: 2rem;

                /**
                 * Font related.
                 */

                line-height: 0;

                /**
                 * .inset.
                 */

                .inset {

                    /**
                     * Background.
                     */

                    background-color: $brand-one;

                    /**
                     * Sizing.
                     */

                    width: 100%;

                    /**
                     * Display.
                     */

                    display: flex;

                }

                /**
                 * 
                 */

                .col-image {

                    /**
                     * Background.
                     */

                    background-position: center;
                    background-size: cover;

                    /**
                     * Position.
                     */

                    position: relative;

                    /**
                     * Content.
                     */

                    content: "";

                    /**
                     * Display.
                     */

                    display: block;

                    /**
                     * Padding.
                     */

                    padding-bottom: 100%;

                    /**
                     * Sizing.
                     */

                    height: 100%;

                }

                /**
                 * .col-a.
                 */

                .col-a {

                    /**
                     * img.
                     */

                    img {

                        max-width: 100%;

                    }

                    /**
                     * Sizing.
                     */

                    width: 40%;

                }

                /**
                 * .col-b.
                 */

                .col-b {

                    /**
                     * Padding.
                     */

                    padding-right: 2rem;

                    /**
                     * .pin.
                     */

                    .pin {

                        /**
                         * Margin.
                         */

                        margin-top: 1.5rem;
                        margin-bottom: 0.8rem;

                        /**
                         * Sizing.
                         */

                        max-width: 1rem;

                    }

                    /**
                     * .country.
                     */

                    .country {

                        /**
                         * Font related.
                         */

                        text-transform: uppercase;
                        font-weight: bold;
                        color: rgba($brand-two, 0.6);

                        /**
                         * Margin.
                         */

                        margin-bottom: 0.4rem;

                    }

                    /**
                     * .location.
                     */

                    .location {

                        /**
                         * Margin.
                         */

                        margin-bottom: 1.5rem;

                        /**
                         * Font related.
                         */

                        font-size: 1.875rem;
                        color: $brand-four;

                    }

                    /**
                     * Padding.
                     */

                    padding-left: 1.5rem;

                    /**
                     * Sizing.
                     */

                    width: 60%;

                    /**
                     * a.
                     */

                    a {

                        margin-bottom: 2rem;

                    }

                }

            }

        }

    }

}