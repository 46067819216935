/**
 * .blogpost-headingarea-wrapper.
 */

.blogpost-headingarea-wrapper {

    /**
     * Padding.
     */

    padding-top: 3rem;
    padding-bottom: 2rem;

    /**
     * blogpost-headingarea-container.
     */

    .blogpost-headingarea-container {

        /**
         * .heading.
         */

        .heading {

            /**
             * Font related.
             */

            text-align: left;
            font-weight: 300;
            text-transform: uppercase;
            font-size: 2.25rem;

            /**
             * Margin.
             */

            margin-bottom: 0.5rem;



        }

        /**
         * .tagline.
         */

        .tagline {

            /**
             * Font related.
             */

            color: $brand-five;

        }

    }

}
