/**
 * html.
 */

html {

    /**
     * body.
     */

    body {

        /**
         * Font related.
         */

        font-family: 'Roboto', sans-serif;

        /**
         * Padding.
         */

        padding-top: 78px;

        /**
         * 
         */

        h2 {

            /**
             * Font related.
             */

            text-align: center;
            font-weight: 300;
            text-transform: uppercase;

            /**
             * Margin.
             */

            margin-bottom: 1.5rem;

        }

        /**
         * 
         */

        p {

            line-height: 1.3rem;

        }
        
        /**
         * 
         */

        a {

            color: #b6a882;

            &:hover {
                
                color: $brand-five;

                text-decoration: none;

            }

        }

        /**
         * 
         */

        .sub-heading {

            color: $brand-two;

            line-height: 1.6rem;

        }

        /**
         * 
         */

        .logo-anchor {

            display: block;

            img.logo {

                max-width: 8rem;

            }

        }

        /**
         * .description-container.
         */

        .description-container {

            /**
             * Margin.
             */

            margin-bottom: 2rem;

            /**
             * Padding.
             */

            padding-left: 4rem;
            padding-right: 4rem;

            /**
             * Font related.
             */

            text-align: center;

        }

    }

}