/**
 * .home-blogposts-wrapper.
 */

.home-blogposts-wrapper {

    /**
     * Background.
     */

    background-color: $brand-three;

    /**
     * Padding.
     */

    padding-top: 3rem;
    padding-bottom: 4rem;

    /**
     * .home-blogposts-container.
     */

    .home-blogposts-container {

        /**
         * .blog-posts-list.
         */

        .blog-posts-list {

            /**
             * Margin.
             */

            margin-bottom: 2rem;

            /**
             * li.
             */

            li {

                /**
                 * breakpoint.
                 */

                @include breakpoint($up-to-desktop) {

                    /**
                     * 
                     */

                    margin-bottom: 2rem;

                }

                /**
                 * .image-container.
                 */

                .image-container {

                    /**
                     * Position.
                     */

                    position: relative;

                    /**
                     * Content.
                     */

                    content: "";

                    /**
                     * Display.
                     */

                    display: block;

                    /**
                     * Padding.
                     */

                    padding-bottom: 100%;

                }

                /**
                 * .inset.
                 */

                .inset {

                    /**
                     * a.
                     */

                    a {

                        /**
                         * Display.
                         */

                        display: block;

                        /**
                         * Background.
                         */

                        background-color: white;

                        /**
                         * Font related.
                         */

                        text-decoration: none;

                        /**
                         * Transition.
                         */

                        transition: background-color 0.05s linear;

                        /**
                         * &:hover.
                         */

                        &:hover {

                            /**
                             * Background.
                             */

                            background-color: $brand-one;

                            /**
                             * p.
                             */

                            p {

                                /**
                                 * Font related.
                                 */

                                color: $brand-four;
                            }                           

                        }

                        /**
                         * .image-container.
                         */

                        .image-container {

                            /**
                             * Background.
                             */

                            background-position: center;
                            background-size: cover;

                            /**
                             * img.
                             */

                            img {

                                /**
                                 * Display.
                                 */

                                display: none;

                            }

                        }

                        /**
                         * .details-container.
                         */

                        .details-container {

                            /**
                             * Padding.
                             */

                            padding: 1.5rem 1.5rem 1.8rem 1.5rem;

                            /**
                             * 
                             */

                            .date {

                                /**
                                 * Font related.
                                 */

                                text-transform: uppercase;
                                color: $brand-two;
                                font-weight: 300;
                                font-size: 0.8rem;

                                /**
                                 * Margin.
                                 */

                                margin-bottom: 0.5rem;

                            }

                        }



                    }

                }

            }

        }        

    }

}