// Remember: do NOT edit this file.

:root {
    font-size: 16px;
}

html footer ul li a {
    font-size: 0.875rem; // 14px
}

html p {
    font-size: 0.875rem; // 14px
}

html h1 {
    font-size: 2.8125rem; // 45px
}

.heading-large {
    font-size: 2.25rem; // 36px
}

.sub-heading {
    font-size: 1.25rem; // 20px
}

html h2 {
    font-size: 2.25rem; // 36px
}

html ul li {
    font-size: 0.875rem; // 14px
}
