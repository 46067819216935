/**
 * .standardhero-wrapper.
 */

.standardhero-wrapper {

    /**
     * Sizing.
     */

    min-height: 25rem;

    /**
     * Background.
     */

    background-position: center;
    background-size: cover;

}