/**
 * .twoblocks-wrapper.
 */

.twoblocks-wrapper {
    
    /**
     * Background.
     */

    background-color: $brand-four;

    /**
     * Padding.
     */

    padding-top: 4rem;
    padding-bottom: 4rem;

    /**
     * .twoblocks-container.
     */

    .twoblocks-container {

        /**
         * 
         */

        align-items: stretch;

        /**
         * 
         */

        .block-one, .block-two {

            /**
             * 
             */

            @include breakpoint($up-to-desktop) {
                
                margin-bottom: 2rem;

            }

            /**
             * &:hover.
             */

            &:hover {

                /**
                 * Font related.
                 */

                text-decoration: none;

                /**
                 * .inset.
                 */

                .inset {

                    /**
                     * Background.
                     */

                    background-color: $brand-one;

                    /**
                     * Transition.
                     */

                    transition: background-color 0.05s linear;

                    /**
                     * .sub-heading.
                     */

                    .sub-heading {

                        /**
                         * Font related.
                         */

                        color: $brand-four;

                    }

                    /**
                     * .linktext.
                     */

                    .linktext {

                        /**
                         * Font related.
                         */

                        color: $brand-four !important;

                    }

                }

            }

            /**
             * .inset.
             */

            .inset {

                /**
                 * Background.
                 */

                background-color: $brand-three;

                /**
                 * @include.
                 */

                @include breakpoint($up-to-ipad) {

                    /**
                     * Padding.
                     */

                    padding-top: 2rem;

                }

                /**
                 * .col-a.
                 */

                .col-a {

                    /**
                     * Font related.
                     */

                    text-align: center;
                    font-weight: 300;

                    /**
                     * Flex.
                     */

                    align-self: center;

                    /**
                     * Padding.
                     */

                    padding-right: 0;

                    /**
                     * .col-inset.
                     */

                    .col-inset {

                        /**
                         * Padding.
                         */

                        padding-left: 15px;

                        /**
                         * @include.
                         */

                        @include breakpoint($up-to-ipad) {

                            /**
                             * Padding.
                             */

                            padding-left: 0;
                            padding-right: 15px;
                            padding-bottom: 2rem;

                        }

                    }

                    /**
                     * .sub-heading.
                     */

                    .sub-heading {

                        /**
                         * Font related.
                         */

                        font-size: 1.625rem;
                        letter-spacing: 0.11rem;

                        /**
                         * @include.
                         */

                        @include breakpoint($desktop) {

                            /**
                             * Font related.
                             */

                            font-size: 1.4rem;

                        }

                        /**
                         * Margin.
                         */

                        margin-bottom: 1rem;

                    }

                    /**
                     * .linktext.
                     */

                    .linktext {

                        /**
                         * Font related.
                         */

                        text-transform: uppercase;
                        font-size: 1rem;
                        color: $brand-five;

                    }

                    /**
                     * img.
                     */

                    img {

                        /**
                         * Sizing.
                         */

                        max-width: 3.5rem;

                        /**
                         * Opacity.
                         */

                        opacity: 0.2;

                        /**
                         * Margin.
                         */

                        margin-bottom: 0.5rem;


                    }

                }

                /**
                 * .col-b.
                 */

                .col-b {

                    /**
                     * Background.
                     */

                    background-position: center;
                    background-size: cover;

                    /**
                     * Sizing.
                     */

                    min-height: 13rem;

                    /**
                     * Font related.
                     */

                    line-height: 0 !important;

                    /**
                     * img.
                     */

                    img {

                        /**
                         * Sizing.
                         */

                        max-width: 100%;

                    }

                }

            }            

        }

        /**
         * .block-two.
         */

        .block-two {
            
            /**
             * &:hover.
             */

            &:hover {

                /**
                 * 
                 */

                .inset {

                    background-color: $brand-one;

                    .sub-heading {

                        color: $brand-three;

                    }

                    p {

                        color: $brand-three;

                    }

                    .linktext {
                        
                        color: $brand-two !important;

                    }

                }

            }

            /**
             * .inset.
             */

            .inset {

                /**
                 * Background.
                 */

                background-color: $brand-two;    

                /**
                 * Sizing.
                 */

                height: 100%;

                /**
                 * .sub-heading.
                 */

                .sub-heading {

                    /**
                     * Margin.
                     */

                    margin-bottom: 0.5rem !important;

                    /**
                     * Font related.
                     */

                    color: $brand-three

                }

                /**
                 * p.
                 */

                p {

                    /**
                     * Margin.
                     */

                    margin-bottom: 1.2rem;

                    /**
                     * Font related.
                     */

                    color: white;

                    /**
                     * Padding.
                     */

                    padding-left: 0.2rem;
                    padding-right: 0.2rem;

                }

                /**
                 * p.linktext.
                 */

                p.linktext {

                    /**
                     * Margin.
                     */

                    margin-bottom: 0;

                    /**
                     * Font related.
                     */

                    color: $brand-one !important;

                }

            }

        }

    }

}