/**
 * .home-hero-wrapper.
 */

.home-hero-wrapper {

    /**
     * Sizing.
     */

    min-height: 25rem;

    /**
     * Background.
     */

    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    /**
     * .home-hero-container.
     */

    .home-hero-container {

    }

}