/**
 * .brands-brandslist-wrapper.
 */

.brands-brandslist-wrapper {

    /**
     * Padding.
     */

    padding-top: 3rem;
    padding-bottom: 16rem;

    /**
     * Background.
     */

    background-color: $brand-three;

    /**
     * .brands-brandslist-container.
     */

    .brands-brandslist-container {

        /**
         * 
         */

        .brands-list{

            /**
             * li.
             */

            li {

                /**
                 * Font related.
                 */

                text-align: center;

                /**
                 * Margin.
                 */

                margin-bottom: 2rem;

                /**
                 * img.
                 */

                img {

                    /**
                     * Display.
                     */

                    display: inline-block;

                    /**
                     * Sizing.
                     */

                    max-width: 100%;

                    /**
                     * Opacity.
                     */

                    opacity: 0.5;

                }

            }

        }

    }

}