/**
 * .home-maps-wrapper.
 */

.home-maps-wrapper {

    /**
     * Background.
     */

    background-color: $brand-one;

    /**
     * Padding.
     */

    padding-top: 3.5rem;
    padding-bottom: 4.5rem;

    /**
     * .home-maps-container.
     */

    .home-maps-container {

        /**
         * h2.
         */

        h2 {

            color: $brand-four;

            margin-bottom: 0.5rem;

        }

        /**
         * .row-maps-mini-list.
         */

        .row-maps-mini-list {

            /**
             * Font related.
             */

            text-align: center;

            /**
             * .maps-mini-list.
             */

            .maps-mini-list {

                /**
                 * Margin.
                 */

                margin-bottom: 2.5rem;

                /**
                 * 
                 */

                li {

                    /**
                     * Display.
                     */

                    display: inline-block;

                    /**
                     * Margin.
                     */

                    margin-right: 1rem;

                    /**
                     * a.
                     */

                    a {

                        /**
                         * Font related.
                         */

                        color: $brand-two;
                        text-transform: uppercase;
                        letter-spacing: 0.05rem;

                        /**
                         * &.active.
                         */

                        &.active {

                            font-weight: bold;

                        }

                        /**
                         * &:hover.
                         */

                        &:hover {

                            text-decoration: none;

                            opacity: 0.8;

                        }

                    }

                }

            }

        }



        /**
         * .map-slider.
         */

        .map-slider {

            /**
             * .map-slide.
             */

            .map-slide {

                /**
                 * Display.
                 */

                display: flex;

                /**
                 * @include.
                 */

                @include breakpoint($up-to-ipad) {

                    /**
                     * Display.
                     */

                    display: block;

                }

                /**
                 * .col-a.
                 */

                .col-a {

                    /**
                     * .inset.
                     */

                    .inset {

                    }

                    /**
                     * img.
                     */

                    img {

                        max-width: 100%;

                    }

                    /**
                     * Sizing.
                     */

                    width: 41%;

                    /**
                     * @include.
                     */

                    @include breakpoint($up-to-ipad) {

                        /**
                         * Sizing.
                         */

                        width: 100%;

                        /**
                         * Padding.
                         */

                        padding: 0;

                    }

                    /**
                     * Padding.
                     */

                    padding-right: 15px;

                }

                /**
                 * .col-b.
                 */

                .col-b {

                    /**
                     * Padding.
                     */

                    padding-right: 2rem;

                    /**
                     * .inset.
                     */

                    .inset {

                    }

                    /**
                     * Padding.
                     */

                    padding-left: 15px;

                    /**
                     * Sizing.
                     */

                    width: 59%;

                    /**
                     * @include.
                     */

                    @include breakpoint($up-to-ipad) {

                        /**
                         * Sizing.
                         */

                        width: 100%;

                        /**
                         * Padding.
                         */

                        padding-left: 0;

                    }

                    /**
                     * .pin.
                     */

                    .pin {

                        /**
                         * Margin.
                         */

                        margin-top: 2rem;
                        margin-bottom: 0.8rem;

                        /**
                         * Sizing.
                         */

                        max-width: 1rem;

                    }

                    /**
                     * .country.
                     */

                    .country {

                        /**
                         * Font related.
                         */

                        text-transform: uppercase;
                        font-weight: bold;
                        color: rgba($brand-two, 0.6);

                        /**
                         * Margin.
                         */

                        margin-bottom: 0.4rem;

                    }

                    /**
                     * .location.
                     */

                    .location {

                        /**
                         * Font related.
                         */

                        font-size: 1.875rem;
                        color: $brand-four;
                        // font-weight: bold;

                    }

                    /**
                     * .divider.
                     */

                    .divider {

                        /**
                         * Display.
                         */

                        display: block;

                        /**
                         * Sizing.
                         */

                        height: 2px;
                        width: 3.5rem;

                        /**
                         * Background.
                         */

                        background-color: rgba($brand-two, 0.2);

                        /**
                         * Margin.
                         */

                        margin-top: 1.3rem;
                        margin-bottom: 1.4rem;

                    }

                    /**
                     * .teaser.
                     */

                    .teaser {

                        /**
                         * Margin.
                         */

                        margin-bottom: 2rem;

                        /**
                         * Font related.
                         */

                        color: $brand-four;

                    }

                }

            }

        }

    }

}