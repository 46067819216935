/**
 * .glossary-alphabet-wrapper.
 */

.glossary-alphabet-wrapper {

    /**
     * Background.
     */

    background-color: $brand-one;;

    /**
     * .glossary-alphabet-container.
     */

    .glossary-alphabet-container {

        /**
         * Font related.
         */

        text-align: center;

        /**
         * Padding.
         */

        padding-top: 1.4rem;
        padding-bottom: 1rem;
        padding-left: 2rem;
        padding-right: 2rem;

        /**
         * ul.
         */

        ul {

            /**
             * li.
             */

            li {

                /**
                 * Font related.
                 */

                font-size: 1.9rem;
                color: $brand-four;
                text-transform: uppercase;
                font-weight: 300;

                /**
                 * Display.
                 */

                display: inline-block;

                /**
                 * Margin.
                 */

                margin-right: 1rem;
                margin-bottom: 0.4rem;

                /**
                 * a.
                 */

                a {

                    /**
                     * Font related.
                     */

                    color: $brand-four;
                    text-decoration: none;

                    /**
                     * &:hover.
                     */

                    &:hover {

                        /**
                         * Color.
                         */

                        color: $brand-two;

                    }

                    /**
                     * &.disable.
                     */  

                    &.disable {

                        /**
                         * Pointer events.
                         */

                        pointer-events: none;

                        /**
                         * Opacity.
                         */

                        opacity: 0.25;

                    }

                }

            }

        }

    }

}